import { DistributionPlatform, DistributionPlatformPermissionEnum } from 'src/api';

export const userHasPermission = (
  distributionPlatforms: DistributionPlatform[] | null,
  permission: DistributionPlatformPermissionEnum,
) =>
  !!distributionPlatforms &&
  distributionPlatforms.some(
    (distributionPlatform) => !!distributionPlatform?.permissions.find((perm) => perm === permission),
  );

export const hasDistributionViewDataPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.ViewPlatformData);

export const hasDistributionManageDataPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.ManageInvestorAndInvestmentData);

export const hasDistributionCreateDataPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.CreateInvestorAndInvestment);

export const hasDistributionManageVouchersPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.ManageVouchers);

export const hasDistributionViewFriendReferralPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.ViewFriendReferrals);

export const hasDistributionManageIdentificationDataPermission = (
  distributionPlatforms: DistributionPlatform[] | null,
) => userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.ManageIdentificationData);
