import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { StateValues } from 'react-use-form-state';
import { SalutationEnum, AdminApi, DistributionPlatform } from 'api';
import useApiCall from 'hooks/use-api-call';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';
import Grid, { Col } from 'ui/atoms/grid';
import { PersonType } from 'ui/types/person';
import { AdminInvestorCreationRequest } from 'api/models/AdminInvestorCreationRequest';
import Icon from 'src/ui/atoms/icon';
import Spacer from 'src/ui/atoms/spacer';
import Hint from 'src/ui/atoms/hint';

interface AddInvestorModalProps {
  onAddNewInvestor: () => void;
  onClose: () => void;
  distributionPlatforms: DistributionPlatform[];
}

interface AddInvestorFields {
  distributionPlatformId: string;
  personType: PersonType;
  firstName: string;
  companyName?: string;
  salutation: SalutationEnum;
  forename: string;
  surname: string;
  email: string;
  pepStatus: boolean;
  isBeneficiary: boolean;
}

export const Form = makeModalForm<AddInvestorFields>();

const AddInvestorModal: FunctionComponent<AddInvestorModalProps> = ({
  onAddNewInvestor,
  onClose,
  distributionPlatforms,
}) => {
  const { makeAuthenticatedApi, withApi } = useApiCall();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const [isPepStatusExtended, setPepStatusExtended] = useState(false);

  const onClickExtendPepDescription = useCallback(
    (evt: any) => {
      evt.preventDefault();
      setPepStatusExtended(!isPepStatusExtended);
    },
    [isPepStatusExtended],
  );

  const onSubmit = useCallback((fieldsValues: StateValues<AddInvestorFields>) => {
    const params: AdminInvestorCreationRequest = {
      distributionPlatformId: fieldsValues.distributionPlatformId,
      salutation: fieldsValues.salutation,
      forename: fieldsValues.forename,
      surname: fieldsValues.surname,
      email: fieldsValues.email,
      isPep: !fieldsValues.pepStatus,
      isBeneficiary: fieldsValues.isBeneficiary,
    };

    if (fieldsValues.personType === PersonType.Legal) {
      params.companyName = fieldsValues.companyName;
    }

    withApi(async () => {
      await api.adminInvestorsCreate({ adminInvestorCreationRequest: params });
      onAddNewInvestor();
    });
  }, []);

  return (
    <Modal onClose={onClose}>
      <Form
        i18nKey="addInvestor"
        onSubmit={(values: StateValues<AddInvestorFields>) => {
          onSubmit(values);
        }}
        initial={{
          personType: PersonType.Natural,
        }}
      >
        <ModalHeader>
          <Translate name="addInvestor.title" />
        </ModalHeader>
        <ModalContent>
          <Form.ValueProvider>
            {(values) => (
              <>
                {!!distributionPlatforms?.length && (
                  <Form.Group name="distributionPlatformId" required={true}>
                    <Form.Select
                      options={distributionPlatforms.map(({ id, platformName }) => ({
                        value: id,
                        label: platformName,
                      }))}
                      portalTarget={document.body}
                    ></Form.Select>
                  </Form.Group>
                )}
                <Form.Group name="personType" required={true}>
                  <Form.GroupToggle>
                    {[PersonType.Natural, PersonType.Legal].map((personType, index) => (
                      <Form.Radio value={personType} key={index}>
                        <Translate name={`personTypes.${personType}`} />
                      </Form.Radio>
                    ))}
                  </Form.GroupToggle>
                </Form.Group>
                {values.personType === PersonType.Legal && (
                  <Form.Group name="companyName" required={true}>
                    <Form.Input />
                  </Form.Group>
                )}
                <Form.Group name="salutation" required={true}>
                  <Form.GroupToggle>
                    {Object.values(SalutationEnum).map((salutation, index) => (
                      <Form.Radio value={salutation} key={index}>
                        <Translate name={`addInvestor.fields.salutations.${salutation.toLowerCase()}`} />
                      </Form.Radio>
                    ))}
                  </Form.GroupToggle>
                </Form.Group>
                <Grid>
                  <Col width={6} tablet={12} phone={12}>
                    <Form.Group name="forename" required={true}>
                      <Form.Input />
                    </Form.Group>
                  </Col>
                  <Col width={6} tablet={12} phone={12}>
                    <Form.Group name="surname" required={true}>
                      <Form.Input />
                    </Form.Group>
                  </Col>
                </Grid>
                <Form.Group name="email" required={true}>
                  <Form.Input />
                  {Form.Validators.Email()}
                </Form.Group>
                <Form.Group name="pepStatus" required={true}>
                  <Form.Checkbox>
                    <Translate name="addInvestor.pepStatus" />
                    <Spacer inline x={2} />
                    <Icon name="question" size="regular" onClick={(evt: any) => onClickExtendPepDescription(evt)} />
                    {isPepStatusExtended && (
                      <Hint variant="info">
                        <Translate name="addInvestor.pepStatusExtended" />
                      </Hint>
                    )}
                  </Form.Checkbox>
                </Form.Group>
                <Form.Group name="isBeneficiary" required={true}>
                  <Form.Checkbox>
                    <Translate name="addInvestor.isBeneficiary" />
                  </Form.Checkbox>
                </Form.Group>
                <Translate name="addInvestor.furtherData" />
              </>
            )}
          </Form.ValueProvider>
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'cancel',
                content: <Translate name="common.close" />,
                size: 'large',
                onClick: () => onClose(),
              },
              {
                name: 'send',
                content: <Translate name="addInvestor.submit" />,
                variant: 'primary',
                type: 'submit',
                size: 'large',
              },
            ]}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddInvestorModal;
