import React, { FunctionComponent, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Link from 'core/routing/link';
import Button from 'ui/atoms/button';
import ConnectedInvestmentsTable from './connected-investments';
import VoucherModal from '../vouchers/create-voucher';
import ProductsList from './products-list';
import * as Styled from './styled';
import IssuerInvestmentsFilters from 'apps/issuer/shared/investments-table/investments-filters';
import { useVoucherDetails } from './hooks';
import LoadingRing from 'ui/atoms/loading-ring';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import BoxedContent from 'ui/molecules/boxed-content';
import Grid, { Col } from 'ui/atoms/grid';
import { InfoField } from 'ui/molecules/info-grid';
import StatusTag from 'ui/atoms/status-tag';
import Date from 'ui/atoms/date';
import Header from 'ui/atoms/header';
import CopyButton from 'ui/molecules/copy-button';
import ActionButtons from 'ui/molecules/action-buttons';
import Currency from 'ui/atoms/currency';

interface IssuerVoucherDetailsPageProps {
  voucherId: string;
}

const IssuerVoucherDetailsPage: FunctionComponent<IssuerVoucherDetailsPageProps> = ({ voucherId }) => {
  const [isEditVoucherModalOpen, setIsEditVoucherModalOpen] = useState(false);
  const [refreshVoucherDetails, setRefreshVoucherDetails] = useState(false);

  const { voucher, steps, connectedInvestments, changeVoucherActivation, loading } = useVoucherDetails(
    voucherId,
    refreshVoucherDetails,
  );

  if (!voucherId || !voucher || loading)
    return (
      <WideContent title={<Translate name="dashboardIssuerVoucherDetails.title" />}>
        <LoadingRing />
      </WideContent>
    );

  return (
    <>
      <VoucherModal
        isEditMode={true}
        isOpen={isEditVoucherModalOpen}
        createdSteps={steps}
        voucher={voucher}
        onModalClose={() => setIsEditVoucherModalOpen(false)}
        onVoucherChanged={() => {
          setRefreshVoucherDetails(false);
          setRefreshVoucherDetails(true);
          setIsEditVoucherModalOpen(false);
        }}
      />
      <Section spacing="large">
        <WideContent title={<Translate name="dashboardIssuerVoucherDetails.title" />} titleSpacing="tiny">
          <Section spacing="medium">{voucher.name}</Section>
          <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
            <Grid spacing="medium">
              <Col width={4}>
                <InfoField title={<Translate name="voucherCode.voucherCode" />}>
                  <Styled.VoucherCodeContainer>
                    <Styled.VoucherCode>{voucher.code}</Styled.VoucherCode>
                    <CopyButton valueToCopy={voucher.code} />
                  </Styled.VoucherCodeContainer>
                </InfoField>
              </Col>
              <Col width={4}>
                <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.createdAt" />}>
                  <Date>{voucher.createdAt}</Date>
                </InfoField>
              </Col>
              <Col width={4}>
                <InfoField title={<Translate name="common.status" />}>
                  <StatusTag variant={voucher.active ? 'success' : 'danger'} truncate={true}>
                    <Translate
                      name={`issuerProductDetails.campaigns.status.${voucher.active ? 'active' : 'deactivated'}`}
                    />
                  </StatusTag>
                </InfoField>
              </Col>
            </Grid>
            <hr />

            <Header size="small">
              <Translate name="dashboardIssuerVoucherDetails.voucherSteps" />
            </Header>
            {steps.length > 0 &&
              steps.map((step, index) => (
                <Grid spacing="medium" key={index}>
                  <Col width={4}>
                    <span>{index + 1}. </span>
                    <Translate name="dashboardIssuerVoucherDetails.minInvestment" />{' '}
                    <Currency>{step.minimumInvestmentAmount}</Currency>
                  </Col>
                  <Col width={6}>
                    <Translate name="createVoucherForm.discount" />{' '}
                    {step.disagioPercent ? <b>{Number(step.disagioPercent)}%</b> : <b>{Number(step.disagio)}</b>}
                  </Col>
                </Grid>
              ))}
            <hr />

            <Header size="small">
              <Translate name="dashboardIssuerVoucherDetails.numberOfUses" />
            </Header>

            <Grid spacing="medium">
              <Col width={4}>
                <InfoField title={<Translate name="dashboardIssuerVoucherDetails.totalUsage" />}>
                  {voucher.numberOfUses === 0 ? <Translate name="createVoucherForm.unlimited" /> : voucher.numberOfUses}
                </InfoField>
              </Col>
              <Col width={6}>
                <InfoField title={<Translate name="dashboardIssuerVoucherDetails.usagePerInvestor" />}>
                  {voucher.firstTimeOnly ? (
                    <Translate name="dashboardIssuerVoucherDetails.firstTime" />
                  ) : (
                    voucher.numberOfUsesInvestor
                  )}
                </InfoField>
              </Col>
            </Grid>
            <Grid>
              <Col width={12}>
                <InfoField title={<Translate name="dashboardIssuerVoucherDetails.validity" />}>
                  {voucher.productNames && voucher.productNames.length > 0 ? (
                    <ProductsList products={voucher.productNames} />
                  ) : (
                    <Translate name="createVoucherForm.validity.allProducts" />
                  )}
                </InfoField>
              </Col>
            </Grid>

            <ActionButtons>
              <Button variant="link" onClick={changeVoucherActivation}>
                <Translate
                  name={`dashboardIssuerVoucherDetails.${voucher.active ? 'deactivateVoucher' : 'activateVoucher'}`}
                />
              </Button>
              <Button variant="primary" onClick={() => setIsEditVoucherModalOpen(true)}>
                <Translate name="dashboardIssuerVoucherDetails.editVoucher" />
              </Button>
            </ActionButtons>
          </BoxedContent>
        </WideContent>
      </Section>

      <WideContent
        title={<Translate name="dashboardIssuerVoucherDetails.connectedInvestments" />}
        ignorePageTitle
        titleSpacing="small"
      >
        <Section spacing="medium">
          <Translate name="dashboardIssuerVoucherDetails.connectedInvestmentsSubtitle" />
        </Section>
        {connectedInvestments?.length === 0 && (
          <Section spacing="small">
            <small>
              <Translate name="dashboardIssuerVoucherDetails.noInvestments" />
            </small>
          </Section>
        )}
        {connectedInvestments && connectedInvestments?.length > 0 && (
          <ConnectedInvestmentsTable
            id={voucherId}
            filters={<IssuerInvestmentsFilters />}
            defaultOrdering={{ direction: 'desc', fieldName: 'signed_date' }}
            defaultLimit={50}
          />
        )}
      </WideContent>

      <WideContent>
        <Link icon="arrow-left" to="/dashboard/issuerVouchers">
          <Translate as="span" name="dashboardIssuerVoucherDetails.goToVouchers" />
        </Link>
      </WideContent>
    </>
  );
};

export default WithAuthenticatedPage(IssuerVoucherDetailsPage, AppType.ISSUER);
